var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "thank-you-view container mt-4" },
    [
      _c("offer-ordered", {
        attrs: {
          "bullet-points": _vm.offer.form.details.bulletPoints,
          description: _vm.offer.thankYou.description,
          image: _vm.offer.form.productImage,
          title: _vm.offer.thankYou.headline
        }
      }),
      _vm.giftVisible
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "container voucher-badge" }, [
              _c("div", { staticClass: "row align-items-center" }, [
                _vm._m(0),
                _c("div", { staticClass: "col-xl-9 text-center" }, [
                  _c("h2", { staticClass: "voucher-badge-headline" }, [
                    _vm._v("Noch mehr Gutscheine sichern?")
                  ]),
                  _c(
                    "div",
                    { staticClass: "bab-line" },
                    [
                      _c(
                        "Button",
                        {
                          attrs: { color: "blue", type: "request" },
                          on: { click: _vm.requestMoreVoucher }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.offer.thankYou.headlineVouchers) +
                              "\n                        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.showFurtherOffers
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _vm.offer.thankYou.headlineOffers ? _vm._m(1) : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm.showFurtherOffers
        ? _c("div", { staticClass: "row" }, [_vm._m(2)])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-3 image-container" }, [
      _c("img", {
        staticClass: "img-fluid",
        attrs: {
          alt: "Voucher Badge",
          src: require("../assets/badge-voucher.png")
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "thank-you-text" }, [
      _vm._v(
        "\n                " +
          _vm._s(_vm.offer.thankYou.headlineOffers) +
          "\n            "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("div", { attrs: { id: "vg-embed" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }