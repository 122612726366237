






















import {Component, Prop} from '@/decorators';
import {PublicVisit, TeaserOffer} from '@/model/backend/campaign-api';
import Vue from 'vue';
import {Action, Getter} from 'vuex-class';

@Component({})
export default class Offer extends Vue {
    @Prop
    private id: string;

    @Getter
    private visit: PublicVisit;

    @Action
    private loadTeaserOffer: (id: string) => Promise<TeaserOffer>;

    private offer: TeaserOffer;

    private loaded: boolean = false;

    public created() {
        this.loadTeaserOffer(this.id)
            .then(offer => {
               this.offer = offer;
               this.loaded = true;
            });
    }

    private get visitUrl(): string {
        let apiUrl = process.env.VUE_APP_CAMPAIGN_URL;

        return `${apiUrl}/visit/${this.visit.id}/featured/${this.offer.id}`;
    }

}
