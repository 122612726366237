








































import Button from '@/components/button.vue';
import OfferOrdered from '@/components/offer-ordered.vue';
import Offer from '@/components/offer.vue';
import {Component} from '@/decorators';
import {
    ApplicationConfiguration,
    PortalSettings,
    PublicDeal,
    PublicSubscriptionPortalOffer,
    PublicVisit
} from '@/model/backend/campaign-api';
import {includeJavaScript} from '@/utils/dom';
import Vue from 'vue';
import {Action, Getter} from 'vuex-class';

@Component({
    components: {Button, Offer, OfferOrdered}
})
export default class ThankYouView extends Vue {
    @Getter
    private offer: PublicSubscriptionPortalOffer;

    @Getter
    private settings: PortalSettings;

    @Getter
    private visit: PublicVisit;

    @Getter
    private deal: PublicDeal;

    @Getter
    private appConfig: ApplicationConfiguration;

    @Getter
    private remainingOffers: string[];

    @Getter
    private showFurtherOffers: boolean;

    @Action
    private reloadVisit: () => Promise<PublicVisit>;

    private giftVisible: boolean = false;

    private headlineCaption: string = '';

    private titleCaption: string = '';

    public created(): void {
        this.reloadVisit();
        if (window.hasOwnProperty('vgEmbed')) {
            this.loadOffers();
        } else {
            includeJavaScript(process.env.VUE_APP_VG_EMBED_SCRIPT, this.loadOffers);
        }

        if (this.appConfig.portalConfiguration.theme === 'breuninger') {
            if (document.querySelector('#breuninger_tracking_script') === null) {
                let tracking_script = document.createElement('script');
                tracking_script.id = 'breuninger_tracking_script';
                tracking_script.type = 'text/javascript';
                tracking_script.src = '//cms.brnstc.de/medias/tracking/emos2_20190307.js';
                tracking_script.addEventListener('load', () => {
                    window.emos3.defaults.siteid = 'Live2';
                    window.emos3.send({
                        content: 'external/dds/thankyou/external',
                        countryid: 'DE',
                        langid: 'de'
                    });
                });
                document.head.appendChild(tracking_script);
            }
        }
    }

    private loadOffers() {
        if (this.showFurtherOffers) {
            window.vgEmbed.no_modal = true;
            window.vgEmbed.load({
                element: 'vg-embed',
                site: process.env.VUE_APP_VG_EMBED_API_URL,
                snippetId: this.visit.snippetId,
                visitId: this.$route.params.visitId,
                onlyOffers: true,
                contact: {},
            });
        }
    }

    private requestMoreVoucher(): void {
        window.document.location.href = `${process.env.VUE_APP_VOUCHER_PORTAL}#/${this.visit.id}/extra-voucher`;
    }

    private get image(): string {
        if (this.settings.useV1Images) {
            return this.offer.thankYou.image;
        } else {
            return this.offer.thankYou.imageV1;
        }
    }

    private get offerWidth() {
        return Math.min(370, window.innerWidth);
    }

    private get oldPortalUrl(): string {
        return `/portal#/${this.$route.params.visitId}/subscription-success/${this.$route.params.offerId}`;
    }
}
