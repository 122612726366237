var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "offer" }, [
    _vm.loaded
      ? _c("div", { staticClass: "offer-box row align-items-center" }, [
          _c("div", { staticClass: "col-md-4" }, [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.visitUrl,
                  target:
                    _vm.offer.offerType === "SUBSCRIPTION" ? "_self" : "_target"
                }
              },
              [
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: { src: _vm.offer.image.vertical }
                })
              ]
            )
          ]),
          _c("div", { staticClass: "col-md-8" }, [
            _c("div", { staticClass: "vg-headline" }, [
              _vm._v(_vm._s(_vm.offer.headline.short))
            ]),
            _c("div", {
              staticClass: "vg-text",
              domProps: { innerHTML: _vm._s(_vm.offer.text.short) }
            }),
            _c(
              "a",
              {
                staticClass: "btn btn-light",
                attrs: {
                  role: "button",
                  href: _vm.visitUrl,
                  target:
                    _vm.offer.offerType === "SUBSCRIPTION"
                      ? "_self"
                      : "_target",
                  title: _vm.offer.ctaCaption.short
                }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.offer.ctaCaption.short) +
                    "\n            "
                )
              ]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }